<template>
  <div class="row">
    <div class="medium-5">
      <woot-input
        :value="value.itemTitle.$model"
        :class="{ error: value.itemTitle.$error }"
        class="horiz-input mx-1"
        :label="
          $t('CONVERSATION_ACTIONS.LIST_VIEW.FIELDS.BUTTON_TEXT.LABEL', {
            appendText: order,
          })
        "
        :error="getErrorMessage(value.itemTitle)"
        @input="onChange('itemTitle', $event)"
      />
    </div>
    <div class="medium-5">
      <woot-input
        :value="value.itemDescription.$model"
        :class="{ error: value.itemDescription.$error }"
        class="horiz-input mx-1"
        :label="
          $t('CONVERSATION_ACTIONS.LIST_VIEW.FIELDS.ITEM_DESCRIPTION.LABEL', {
            appendText: order,
          })
        "
        :error="getErrorMessage(value.itemDescription)"
        @input="onChange('itemDescription', $event)"
      />
    </div>
    <div class="actions medium-2 horiz-input self-center">
      <woot-button
        variant="link"
        color-scheme="secondary"
        class-names="grey-btn del-button"
        icon="ion-close-circled"
        @click="$emit('remove')"
      />
    </div>
  </div>
</template>
<script>
import { onlineWaEditTemplateMixin } from '../../../routes/dashboard/settings/onlinewasettings/templates/forms/helpers/validations';

export default {
  mixins: [onlineWaEditTemplateMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onChange(key, value) {
      this.$emit('input', {
        [key]: value,
      });
    },
  },
};
</script>
