<template>
  <div ref="editor_root" class="editor-root">
    <div ref="editor" />

    <div class="emoji-wrapper">
      <emoji-input
        v-if="showEmojiPicker"
        v-on-clickaway="hideEmojiPicker"
        :on-click="emojiOnClick"
      />
    </div>
  </div>
</template>

<script>
import { keymap } from 'prosemirror-keymap';
import { menuBar } from 'prosemirror-menu';
import { EditorView } from 'prosemirror-view';
import EmojiInput from 'shared/components/emoji/EmojiInput';
import { mixin as clickaway } from 'vue-clickaway';
import { Plugin } from 'prosemirror-state';

import {
  suggestionsPlugin,
  triggerCharacters,
} from '@chatwoot/prosemirror-schema/src/mentions/plugin';
import { EditorState } from 'prosemirror-state';

import {
  whatsappMarkdownParser as defaultMarkdownParser,
  whatsappMarkdownSerializer as defaultMarkdownSerializer,
  schema,
} from './markdown';

// import '@chatwoot/prosemirror-schema/src/woot-editor.css';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { buildMenuItems } from './menu';
import { baseKeymap } from 'prosemirror-commands';
import { buildKeymap } from './keymap';
import { buildInputRules } from './inputrules';


export default {
  name: 'WootMessageEditor',
  components: {
    EmojiInput,
  },
  mixins: [eventListenerMixins, clickaway],
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    isFormatMode: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    enableVariables: { type: Boolean, default: true },
  },
  data() {
    return {
      lastValue: null,
      showUserMentions: false,
      showCannedMenu: false,
      mentionSearchKey: '',
      cannedSearchTerm: '',
      editorView: null,
      range: null,
      showEmojiPicker: false,
    };
  },
  computed: {
    plugins() {
      return [
        new Plugin({
          props: {
            clipboardTextParser: (text, $context, plain) => {
              const d = defaultMarkdownParser.parse(text);
              return d.slice(0);
            },
          },
        }),
      ];
    },
  },
  watch: {
    disabled(value) {
      this.view.setProps({
        editable: () => !value,
      });
    },
    value(newValue = '') {
      if (newValue !== this.lastValue) {
        const { tr } = this.state;
        if (this.isFormatMode) {
          this.state = this.createState(
            newValue,
            this.placeholder,
            this.plugins,
            this.isFormatMode
          );
        } else {
          tr.insertText(newValue, 0, tr.doc.content.size);
          this.state = this.view.state.apply(tr);
        }
        this.view.updateState(this.state);
      }
    },
  },
  created() {
    this.state = this.createState(this.value, this.placeholder, this.plugins);
  },
  mounted() {
    this.view = new EditorView(this.$refs.editor, {
      state: this.state,
      dispatchTransaction: tx => {
        this.state = this.state.apply(tx);
        this.emitOnChange();
      },
      handleDOMEvents: {},
    });
    this.view.onEmoji = () => {
      if (!this.showEmojiPicker) {
        setTimeout(() => (this.showEmojiPicker = true), 300);
      }
    };
  },
  methods: {
    createState(content, placeholder, plugins = []) {
      return EditorState.create({
        doc: defaultMarkdownParser.parse(content),
        plugins: [
          buildInputRules(schema),
          keymap(buildKeymap(schema)),
          keymap(baseKeymap),
          ...plugins,
          menuBar({
            floating: false,
            content: buildMenuItems(schema, this.enableVariables).inlineMenu,
          }),
        ],
      });
    },
    handleKeyEvents() {},
    emitOnChange() {
      this.view.updateState(this.state);
      this.lastValue = defaultMarkdownSerializer.serialize(this.state.doc);
      this.$emit('input', this.lastValue);
    },
    onBlur() {
      this.resetTyping();
      this.$emit('blur');
    },

    getValueHtml() {
      const arr = Array.prototype.slice.call(
        this.view.docView.contentDOM.children
      );
      return arr.map(x => x.innerHTML).join('\n');
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    emojiOnClick(emoji) {
      // this.state.tr.insertText("ddd")
      console.log(emoji);
      this.view.dispatch(this.state.tr.insertText(emoji));
      // this.view.updateState(this.state);
    },
  },
};
</script>

<style lang="scss" scoped>
.ProseMirror-menubar-wrapper {
  display: flex;
  flex-direction: column;

  > .ProseMirror-menubar {
    font-family: math;
  }
  .ProseMirror {
    padding: 0;
    word-break: break-word;
  }
}

.editor-root {
  width: 100%;
}

.ProseMirror-woot-style {
  min-height: 8rem;
  max-height: 12rem;
  overflow: auto;
}

.message-editor {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-normal);
  padding: 0 var(--space-slab);
  margin-bottom: 0;
}

.editor_warning {
  border: 1px solid var(--r-400);
}

.editor-warning__message {
  color: var(--r-400);
  font-weight: var(--font-weight-normal);
  padding: var(--space-smaller) 0 0 0;
}

//my
.editor-root {
  border: 1px solid #e0e6ed;
  border-radius: var(--border-radius-normal);
  padding: 0 1rem;
  margin-bottom: var(--border-radius-normal);
  font-size: 0.8rem;
  position: relative;
}

.emoji-dialog {
  top: -50px;
  bottom: unset;
  right: unset;
  left: -320px;
  z-index: 1000;
  //width: 280px;

  &::before {
    display: none;
  }
}

div[dir='rtl'] {
  .emoji-wrapper {
    position: fixed;
    z-index: 1000;
    .emoji-dialog {
      left: unset;
      right: -320px;
      top: -200px;
    }
  }
}

.emoji {
  z-index: 11;
}
</style>
