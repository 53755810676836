<template>
  <div :class="{ error: value.$error }">
    <label>{{ $t('WA_SETTINGS.TEMPLATES.FIELDS.BODY.LABEL') }}</label>
    <editor
      ref="bodyEditor"
      v-model="body"
      class="input"
      :enable-variables="false"
      :placeholder="$t('WA_SETTINGS.TEMPLATES.FIELDS.BODY.PLACEHOLDER')"
      :min-height="4"
      @input="$emit('input', $event)"
    />
    <span v-if="value.$error" class="message">
      {{ getErrorMessage(value) }}
    </span>
  </div>
</template>
<script>
import Editor from 'dashboard/routes/dashboard/settings/onlinewasettings/components/editor/Index.vue';
import { onlineWaEditTemplateMixin } from '../../../routes/dashboard/settings/onlinewasettings/templates/forms/helpers/validations';
export default {
  components: {
    Editor,
  },
  mixins: [onlineWaEditTemplateMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      body: this.value.$model,
    };
  },
};
</script>
<style scoped>
.error .editor-root {
  border: 1px solid red;
}
</style>
