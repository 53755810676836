<template>
  <div class="row">
    <div class="medium-12 column">
      <wa-message-header-input
        :header="$v.header"
        @input="updateField('header', $event)"
        @file="file = $event"
      />
      <wa-message-body-input
        :value="$v.body"
        @input="updateField('body', $event)"
      />
      <wa-message-footer-input
        :value="$v.footer"
        @input="updateField('footer', $event)"
      />
    </div>
    <add-button :options="addButtonOptions" />
    <div class="medium-12 column">
      <button-option
        v-for="(button, idx) in $v.buttons.$each.$iter"
        :key="idx"
        :label="getButtonLabel(button, parseInt(idx) + 1)"
        :value="button"
        :show-remove-icon="idx > 0"
        @input="updateButton(idx, $event)"
        @remove="removeButton(idx)"
      />
    </div>
  </div>
</template>
<script>
import WaMessageHeaderInput from './WaMessageHeaderInput.vue';
import WaMessageBodyInput from './WaMessageBodyInput.vue';
import WaMessageFooterInput from './WaMessageFooterInput.vue';
import ButtonOption from './Button.vue';
import AddButton from './AddButton.vue';
import {
  required,
  maxLength,
  minLength,
  url,
  email,
} from 'vuelidate/lib/validators';
import { isPhoneIsrael } from '../../../../shared/helpers/Validators';

const externalButton = (value, vm) => {
  if (vm.type === 'button') {
    return true;
  }
  return isPhoneIsrael(value) || email(value) || url(value);
};

export default {
  components: {
    ButtonOption,
    AddButton,
    WaMessageBodyInput,
    WaMessageFooterInput,
    WaMessageHeaderInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttons: [],
      body: '',
      header: 1,
      footer: null,
      file: null,
    };
  },
  computed: {
    addButtonOptions() {
      return [
        {
          name: this.$t(
            'CONVERSATION_ACTIONS.BUTTONS_VIEW.ADD_BUTTON.SIMPLE_BUTTON'
          ),
          id: 'website',
          description: '',
          disabled: this.disableAddButton,
          action: () => {
            this.addButton({});
          },
        },
        {
          name: this.$t(
            'CONVERSATION_ACTIONS.BUTTONS_VIEW.ADD_BUTTON.EXTERNAL_BUTTON'
          ),
          id: 'website2',
          description: '',
          disabled: this.disableExternalButton,
          action: () => {
            this.addButton({ type: 'external' });
          },
        },
      ];
    },
    disableAddButton() {
      return (
        this.buttons.length >= this.$v.buttons.$params.maxLength.max ||
        this.hasExternalButton
      );
    },
    hasExternalButton() {
      return !!this.buttons.filter(({ type }) => type === 'external').length;
    },
    disableExternalButton() {
      return this.hasExternalButton || this.buttons.length > 1;
    },
  },
  watch: {
    buttons() {
      this.sync();
    },
    body() {
      this.sync();
    },
    header() {
      this.sync();
    },
    footer() {
      this.sync();
    },
    file() {
      this.sync();
    },
  },
  created() {
    this.addButton({});
    this.$v.$touch();
  },
  validations: {
    header: {
      required,
    },
    footer: {
      maxLength: maxLength(60),
    },
    body: {
      required,
      maxLength: maxLength(1024),
    },
    buttons: {
      minLength: minLength(1),
      maxLength: maxLength(3),
      $each: {
        text: {
          externalButton,
          required,
          maxLength: maxLength(24),
        },
      },
    },
  },
  methods: {
    getButtonLabel(button, idx) {
      if (button.$model.type === 'button') {
        return this.$t(
          'CONVERSATION_ACTIONS.BUTTONS_VIEW.FIELDS.BUTTON.LABEL',
          {
            appendText: idx,
          }
        );
      }

      return this.$t(
        'CONVERSATION_ACTIONS.BUTTONS_VIEW.FIELDS.EXTERNAL_BUTTON.LABEL'
      );
    },
    newButton(type = 'button') {
      if (type === 'button') {
        return {
          type: 'button',
          text: '',
        };
      }
      return {
        type: 'external',
        text: '',
      };
    },
    addButton(defaultParams) {
      this.buttons.push({ ...this.newButton(), ...defaultParams });
      this.$v.buttons.$touch();
    },
    updateButton(idx, button) {
      const copy = { ...this.buttons[idx], ...button };
      this.$set(this.buttons, idx, copy);
      this.$v.buttons.$touch();
    },
    removeButton(idx) {
      this.$delete(this.buttons, idx);
      this.$v.buttons.$touch();
    },
    updateField(key, value) {
      this.$set(this, key, value);
      this.$v[key].$touch();
    },
    sync() {
      const { buttons, body, header, footer, file } = this;
      this.$emit('input', { buttons, body, header, footer, file });
      this.$emit('valid', !this.$v.$invalid);
    },
  },
};
</script>
