import { Schema } from 'prosemirror-model';
import {MarkdownParser, MarkdownSerializer} from "prosemirror-markdown";
import markdownit from "markdown-it";
import waMdPlugin from './wa_template_markdown_plugins'

// ::Schema Document schema for the data model used by Whatsapp.
var schema = new Schema({
  nodes: {
    doc: {
      content: "block+"
    },

    paragraph: {
      content: "inline*",
      group: "block",
      parseDOM: [{tag: "p"}],
      toDOM: function toDOM() { return ["p", 0] }
    },

    text: {
      group: "inline"
    },

    hard_break: {
      inline: true,
      group: "inline",
      selectable: false,
      parseDOM: [{tag: "br"}],
      toDOM: function toDOM() { return ["br"] }
    },

    templ_var: {
      inline: true,
      group: "inline",
      selectable: true,
      parseDOM: [{tag: "input"}],
      toDOM: function toDOM() { return ["input"] }
    },
  },

  marks: {
    em: {
      parseDOM: [{tag: "i"}, {tag: "em"},
        {style: "font-style", getAttrs: function (value) { return value === "italic" && null; }}],
      toDOM: function toDOM() { return ["em"] }
    },

    strong: {
      parseDOM: [{tag: "b"}, {tag: "strong"},
        {style: "font-weight", getAttrs: function (value) { return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null; }}],
      toDOM: function toDOM() { return ["strong"] }
    },

    s: {
      parseDOM: [{tag: "del"}],
      toDOM: function toDOM() { return ["del"] }
    },

  }
});

var whatsappMarkdownParser = new MarkdownParser(schema, markdownit('zero')
    .disable(['paragraph'])
    .use(waMdPlugin)
  , {
  paragraph: {block: "paragraph"},
  hardbreak: {node: "hard_break"},

  em: {mark: "em"},
  strong: {mark: "strong"},
  s: {mark: 's'},

  })
;

var whatsappMarkdownSerializer = new MarkdownSerializer({
  paragraph: function paragraph(state, node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  hard_break: function hard_break(state, node, parent, index) {
    for (var i = index + 1; i < parent.childCount; i++)
    { if (parent.child(i).type !== node.type) {
      state.write("\n");
      return
    } }
  },
  text: function text(state, node) {
    state.text(node.text, false);
  }
}, {
  em: {open: "_", close: "_", mixable: true, expelEnclosingWhitespace: true},
  strong: {open: "*", close: "*", mixable: true, expelEnclosingWhitespace: true},
  s: {open: "~", close: "~", mixable: true, expelEnclosingWhitespace: true},
});

export {whatsappMarkdownParser, whatsappMarkdownSerializer, schema}
