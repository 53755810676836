import {
  required,
  minLength,
  maxLength,
  requiredIf,
  url,
} from 'vuelidate/lib/validators';

const MAX_BODY_VARIABLIES_COUNT = 10;
export const MAX_REPLIES_BUTTONS_COUNT = 10;
const MAX_WEBSITE_BUTTONS_COUNT = 2;

const touchMap = new WeakMap();

const alphaNum = v => {
  return !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/.test(
    v
  );
};

const quickReplies = Array.from(
  { length: MAX_REPLIES_BUTTONS_COUNT },
  (_, i) => i + 1
).reduce((acc, idx) => {
  const key = `quickReply${idx}`;
  return {
    ...acc,
    [key]: {
      required: requiredIf(function (item) {
        return [3, 4].includes(item.buttons) && item[key] !== undefined;
      }),
      alphaNum,
      maxLength: maxLength(25),
      doubledLabel: (v, item) => {
        const replies = Array.from(
          { length: MAX_REPLIES_BUTTONS_COUNT },
          (_, i) => i + 1
        ).map(number => item[`quickReply${number}`]);
        return !v || replies.includes(item[key]);
      },
    },
  };
}, {});

const bodyVariables = Array.from(
  { length: MAX_BODY_VARIABLIES_COUNT },
  (_, i) => i + 1
).reduce((acc, idx) => {
  return {
    ...acc,
    [`bodyVariableSample${idx}`]: {
      required: requiredIf(function (item) {
        return item.body && item.body.includes(`{{${idx}}}`);
      }),
    },
  };
}, {});

const websites = Array.from(
  { length: MAX_WEBSITE_BUTTONS_COUNT },
  (_, i) => i + 1
).reduce((acc, idx) => {
  const key = idx === 1 ? '' : idx;
  return {
    ...acc,
    [`callToActionWebsiteText${key}`]: {
      required: requiredIf(function (item) {
        if (item[`callToActionWebsiteText${key}`] === undefined) return false;
        return [1, 3, 4, 5].includes(item.callToAction) || item.useCoupon === 1;
      }),
      alphaNum,
      maxLength: maxLength(20), // 99 api = 20 but fb = 25
    },
    [`callToActionWebsiteURL${key}`]: {
      required: requiredIf(function (item) {
        if (item[`callToActionWebsiteText${key}`] === undefined) return false;
        return [1, 3, 4, 5].includes(item.callToAction);
      }),
      maxLength: maxLength(2000),
      url,
      onlyHttps: v => !v || v.includes('https'),
    },
    [`callToActionWebsiteType${key}`]: {
      required: requiredIf(function (item) {
        if (item[`callToActionWebsiteText${key}`] === undefined) return false;
        return [1, 3, 4, 5].includes(item.callToAction);
      }),
    },
  };
}, {});
const validators = {
  inbox_id: {
    required,
  },
  language: {
    required,
  },
  name: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(512),
    min1EngLetter: v => /[a-zA-Z]/.test(v),
  },
  header: {
    required,
    maxLength: maxLength(60),
    noCoupon: (value, item) => {
      // eslint-disable-next-line eqeqeq
      if (item.useCoupon == 1 && value === 4) return false;
      return true;
    },
  },
  body: {
    required,
    maxLength: (value, item) => {
      return maxLength(item.useCoupon === 1 ? 600 : 1024)(value);
    },
    skippedVariables: v => {
      for (let i = 2; i <= MAX_BODY_VARIABLIES_COUNT; i++) {
        if (v.includes(`{{${i}}}`) && !v.includes(`{{${i - 1}}}`)) return false;
      }
      return true;
    },
  },
  footer: {
    maxLength: maxLength(60),
    noCoupon: (value, item) => {
      // eslint-disable-next-line eqeqeq
      if (item.useCoupon == 1 && value) return false;
      return true;
    },
  },
  buttons: {
    required,
  },
  ...quickReplies,
  ...websites,
  ...bodyVariables,
  callToActionCallText: {
    required: requiredIf(function (item) {
      return (
        item.callToActionCallText !== undefined &&
        [1, 2, 3, 4].includes(item.callToAction)
      );
    }),
    alphaNum,
    maxLength: maxLength(20), // 99 api = 20 but fb = 25
  },
  callToActionCallNumber: {
    required: requiredIf(function (item) {
      return (
        item.callToActionCallNumber !== undefined &&
        [1, 2, 3, 4].includes(item.callToAction)
      );
    }),
    maxLength: maxLength(20),
    phone: v => !v || /^\+\d{10,14}$/.test(v),
  },
  callToAction: {
    anySelected: (v, item) => {
      return item.buttons !== 2 || v > 0 || (item.useCoupon && v === 0);
    },
  },
  useCoupon: {},
  couponExpiration: {
    required: requiredIf(function (item) {
      return item.useCoupon;
    }),
  },
  couponTitle: {
    required: requiredIf(function (item) {
      return item.useCoupon;
    }),
    alphaNum,
    maxLength: maxLength(20),
  },
  groupStep1: ['inbox_id', 'language', 'name'],
  groupStep2: [
    'header',
    'body',
    'footer',
    'buttons',
    ...Array.from(
      { length: MAX_REPLIES_BUTTONS_COUNT },
      (v, i) => `quickReply${i + 1}`
    ),
    'callToActionWebsiteText',
    'callToActionWebsiteURL',
    'callToActionWebsiteType',
    'callToActionWebsiteText2',
    'callToActionWebsiteURL2',
    'callToActionWebsiteType2',
    'callToActionCallText',
    'callToActionCallNumber',
    'callToAction',
  ],
  groupStep3: [
    'bodyVariableSample1',
    'bodyVariableSample2',
    'bodyVariableSample3',
    'bodyVariableSample4',
    'bodyVariableSample5',
    'bodyVariableSample6',
    'bodyVariableSample7',
    'bodyVariableSample8',
    'bodyVariableSample9',
    'bodyVariableSample10',
  ],
};

const onlineWaEditTemplateMixin = {
  methods: {
    getErrorMessage(el, { prefix, params } = {}) {
      let errorMessage = '';
      if (!el || !el.$error) {
      } else if (el.required === false) {
        errorMessage = this.$t('WA_SETTINGS.TEMPLATES.ERROR_REQUIRED');
      } else if (el.minLength === false) {
        errorMessage = this.$t('WA_SETTINGS.TEMPLATES.ERROR_MIN_LENGTH', {
          len: el.$params.minLength?.min,
        });
      } else if (el.maxLength === false) {
        errorMessage = this.$t('WA_SETTINGS.TEMPLATES.ERROR_MAX_LENGTH', {
          len: el.$params.maxLength?.max,
        });
      } else if (el.anySelected === false) {
        errorMessage = this.$t('WA_SETTINGS.TEMPLATES.ERROR_ANY_SELECTED', {
          label: params.length ? params[0] : '',
        });
      } else if (el.alphaNum === false) {
        errorMessage = this.$t('WA_SETTINGS.TEMPLATES.ERROR_ALPHA_NUM');
      } else if (el.externalButton === false) {
        errorMessage = this.$t(
          'CONVERSATION_ACTIONS.BUTTONS_VIEW.FIELDS.EXTERNAL_BUTTON.ERROR'
        );
      }

      return prefix ? `${prefix} ${errorMessage}` : errorMessage;
    },

    delayTouch($v, delay = 2000) {
      // $v.$reset()
      if (touchMap.has($v)) {
        $v.$reset();
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, delay));
    },

    isValid() {
      const res = !this.$v.form.$invalid;
      if (!res) {
        const invalidFields = Object.keys(this.$v.form.$params)
          .filter(fieldName => this.$v.form[fieldName].$invalid)
          .map(x => x.replace('item.', ''));
        console.log('invalid fields: ', invalidFields.join(', '));
      }

      return res;
    },

    getMaxBodyVariableCounts() {
      return MAX_BODY_VARIABLIES_COUNT;
    },
  },
};

export { validators as default, onlineWaEditTemplateMixin };
