<template>
  <div class="row">
    <div class="medium-6">
      <label class="mx-1" :class="{ error: $v.contact.number.$error }">
        {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
        <contact-input-search
          :phone-number.sync="contact.number"
          field="phone_number"
          @on-select-contact="onSelectContact"
          @input="onPhoneNumberInput"
        />
        <span v-if="$v.contact.number.$error" class="message">
          {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR') }}
        </span>
      </label>
    </div>
    <div class="medium-6">
      <label class="mx-1">
        {{ $t('NEW_CONVERSATION.FORM.TO.LABEL') }}
        <input
          v-model.trim="contact.name"
          type="text"
          :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
        />
      </label>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { isPhoneIsrael } from '../../../../shared/helpers/Validators';
import ContactInputSearch from '../../../modules/contact/components/ContactInputSearch.vue';
export default {
  components: {
    ContactInputSearch,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contact: {
        number: '',
        name: '',
      },
    };
  },
  computed: {
    addButtonOptions() {
      return [
        {
          name: 'select contact',
          id: 'select',
          description: '',
          disabled: false,
          action: () => {},
        },
        {
          name: 'new contact',
          id: 'new',
          description: '',
          disabled: false,
          action: () => {},
        },
      ];
    },
  },
  created() {},
  methods: {
    onSelectContact(contact) {
      this.contact.number = contact.phone_number;
      this.contact.name = contact.name;
      this.$v.contact.$touch();
      this.sync();
    },
    onPhoneNumberInput($event) {
      this.contact.number = $event;
      this.$v.contact.$touch();
      this.sync();
    },
    sync() {
      this.$emit('input', this.contact);
      this.$emit('valid', !this.$v.$invalid);
    },
  },
  validations: {
    contact: {
      number: {
        required,
        isPhoneIsrael(val) {
          return isPhoneIsrael(val);
        },
      },
    },
  },
};
</script>
