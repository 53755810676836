<template>
  <woot-input
    :value="value.$model"
    :class="{ error: value.$error }"
    class="medium-12 columns"
    :label="$t('WA_SETTINGS.TEMPLATES.FIELDS.FOOTER.LABEL')"
    :placeholder="$t('WA_SETTINGS.TEMPLATES.FIELDS.FOOTER.PLACEHOLDER')"
    :error="getErrorMessage(value)"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { onlineWaEditTemplateMixin } from '../../../routes/dashboard/settings/onlinewasettings/templates/forms/helpers/validations';

export default {
  mixins: [onlineWaEditTemplateMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
