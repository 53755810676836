<template>
  <form @submit.prevent="changePassword()">
    <div ref="changePassword" class="profile--settings--row text-black-900 dark:text-slate-300 row">
      <div class="columns small-3">
        <h4 class="block-title text-black-900 dark:text-slate-200">
          {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE') }}
        </h4>
        <p>{{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE') }}</p>
      </div>
      <div class="columns small-9 medium-5"
        :class="{ 'rtl': isHebrewLocale }"><!--        d99d-->
        <password-input
          v-model="currentPassword"
          type="password"
          :class="{ error: $v.currentPassword.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL')"
          :placeholder="
            $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER')
          "
          :error="
            $v.currentPassword.$error
              ? $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.ERROR')
              : ''
          "
          @blur="$v.currentPassword.$touch"
        />

        <password-input
          v-model="password"
          type="password"
          :class="{ error: $v.password.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')"
          :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
          :error="
            $v.password.$error ? passwordErrorMessage : ''
          "
          @blur="$v.password.$touch"
        />
        <!--        d99d-->
        <div v-if="shoRenewPasswordHelpMessage" class="is-error flex justify-start">
          {{ $t('PROFILE_SETTINGS.CHANGE_PASSWORD_HELP_TEXT') }}
        </div>
        <ul class="requirements">
          <li
            v-for="(requirement, key) in passwordRequirements"
            :key="key"
            :class="{ 'is-success': requirement.predicate, 'is-error': !requirement.predicate }"
          >
            {{ requirement.name }}
          </li>
        </ul>
        <!--        . -->
        <password-input
          v-model="passwordConfirmation"
          type="password"
          :class="{ error: $v.passwordConfirmation.$error }"
          :label="$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')"
          :placeholder="
            $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')
          "
          :error="
            $v.passwordConfirmation.$error
              ? $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
              : ''
          "
          @blur="$v.passwordConfirmation.$touch"
        />

        <woot-button
          :is-loading="isPasswordChanging"
          type="submit"
          :disabled="
            !currentPassword ||
            !passwordConfirmation ||
            !$v.passwordConfirmation.isEqPassword ||
              !$v.password.validatePassword
          "
        >
          {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT') }}
        </woot-button>
      </div>
    </div>
  </form>
</template>

<script>
import { requiredIf, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import PasswordInput from 'dashboard/components/widgets/forms/PasswordInput';
const PASSWORD_MIN_LENGTH = window.chatwootConfig.passwordMinLength || 6 // d99d

export default {
  components: { PasswordInput },
  mixins: [alertMixin],
  data() {
    return {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      isPasswordChanging: false,
      errorMessage: '',
    };
  },
  validations: {
    currentPassword: {
      required: requiredIf(function() {return this.currentPassword}),
    },
    password: {
      minLength: minLength(PASSWORD_MIN_LENGTH),
      validatePassword(value) {
        return this.validatePassword(value);
      },
    },
    passwordConfirmation: {
      minLength: minLength(PASSWORD_MIN_LENGTH),
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),
    // d99d
    isHebrewLocale() {
      return this.$i18n.locale === 'he' || this.$i18n.locale === 'ar';
    },
    shoRenewPasswordHelpMessage() {
      const { currentUser } = this;
      return (
        currentUser.password_attr.pass_expired &&
        currentUser.password_attr.reset
      );
    },
    passwordRequirements() {
      return [
        {
          name: this.$t('SET_NEW_PASSWORD.PASSWORD.ERROR_UPPERCASE'),
          predicate: this.hasUppercase(this.password),
        },
        {
          name: this.$t('SET_NEW_PASSWORD.PASSWORD.ERROR_LOWERCASE'),
          predicate: this.hasLowercase(this.password),
        },
        {
          name: this.$t('SET_NEW_PASSWORD.PASSWORD.ERROR_NUMBER'),
          predicate: this.hasNumber(this.password),
        },
        {
          name: this.$t('SET_NEW_PASSWORD.PASSWORD.ERROR_COUNT'),
          predicate: this.password.length >= PASSWORD_MIN_LENGTH,
        },
        {
          name: this.$t('SET_NEW_PASSWORD.PASSWORD.ERROR_SYMBOL'),
          predicate: this.hasSpecialChar(this.password),
        },
      ];
    },
    // .
    passwordErrorMessage() {
      if (!this.$v.password.minLength)
        return this.$t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR', {passwordMinLength: PASSWORD_MIN_LENGTH});
      if (!this.$v.password.validatePassword)
        return this.$t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR_ISO');

      return '';
    },
  },
  mounted() {
      this.scrollToPassword()
  },
  watch: {
    '$route.hash'() {
      this.scrollToPassword()
    }
  },
  methods: {
    // d99d
    hasUppercase(value) {
      return /[A-Z]/.test(value);
    },
    hasLowercase(value) {
      return /[a-z]/.test(value);
    },
    hasNumber(value) {
      return /\d/.test(value);
    },
    hasSpecialChar(value) {
      return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    },
    // .
    scrollToPassword() {
      if (this.$route.hash === '#changePassword')
        this.$refs.changePassword?.scrollIntoView({ behavior: 'smooth' })
    },
    validatePassword(password) {
      if (!password) return true;

      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/;
      return passwordRegex.test(password);
    },
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      try {
        await this.$store.dispatch('updateProfile', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          current_password: this.currentPassword,
        });
        this.password = '';
        this.passwordConfirmation = '';
        this.currentPassword = '';
        this.errorMessage = this.$t('PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS');
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.message) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.isPasswordChanging = false;
        this.showAlert(this.errorMessage);
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings--row {
  @include border-normal-bottom;
  padding: var(--space-normal);
  .small-3 {
    padding: var(--space-normal) var(--space-medium) var(--space-normal) 0;
  }
  .small-9 {
    padding: var(--space-normal);
  }
}
//d99d
.is-success {
  color: #96CA2D;
}
.is-error {
  color: #BA3637;
}
.rtl {
  direction: rtl;
}
//.
</style>
