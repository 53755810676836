<template>
  <div class="settings--content">
      <settings-section
        :title="$t('AGENT_BOTS.BOT_CONFIGURATION.TITLE')"
        :sub-title="$t('AGENT_BOTS.BOT_CONFIGURATION.DESC')"
      >
        <loading-state v-if="isFetching" />
        <form
            v-else
            class="mx-0 flex flex-wrap"
            @submit.prevent="updateActiveAgentBot"
        >
        <div class="w-[60%]">
          <label>
            <select v-model="selectedAgentBotId">
              <option value="" disabled selected>
                {{ $t('AGENT_BOTS.BOT_CONFIGURATION.SELECT_PLACEHOLDER') }}
              </option>
              <option
                v-for="agentBot in agentBots"
                :key="agentBot.id"
                :value="agentBot.id"
              >
                {{ agentBot.name }}
              </option>
            </select>
          </label>
          <div class="button-container">
            <woot-submit-button
              :button-text="$t('AGENT_BOTS.BOT_CONFIGURATION.SUBMIT')"
              :loading="uiFlags.isSettingAgentBot"
            />
            <woot-button
              type="button"
              :disabled="!selectedAgentBotId"
              :loading="uiFlags.isDisconnecting"
              variant="smooth"
              color-scheme="alert"
              class="button--disconnect"
              @click="disconnectBot"
            >
              {{ $t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECT') }}
            </woot-button>
          </div>
        </div>
        </form>
      </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import alertMixin from 'shared/mixins/alertMixin';
import botsAPI from "../../../../../api/agentBots";
import {throwErrorMessage} from "../../../../../store/utils/api";

export default {
  components: {
    LoadingState,
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgentBotId: null,
      agentBots: [],
      isFetching: false,
      isSettingAgentBot: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agentBots/getUIFlags',
    }),
    activeAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](this.inbox.id);
    },
  },
  watch: {
    activeAgentBot() {
      this.selectedAgentBotId =  this.activeAgentBot?.bot_type === 'studio' 
          ? this.activeAgentBot.outgoing_url
          : null;
    },
  },
  async mounted() {
    this.load();
    this.$store.dispatch('agentBots/get');
    this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id)
  },

  methods: {
    async load() {
      this.isFetching = true;
      try {
          const { data } = await botsAPI.getStudioBots();
          this.agentBots = data.map((bot) => ({
              id: `/bots/${bot.id}/`,
              name: bot.name,
          }));
          
         // commit(types.ADD_AGENT_BOT, data);
      } catch (error) {
          throwErrorMessage(error);
      } finally {
          this.isFetching = false;
      }
      // await this.$store.dispatch('agentBots/get');
      //await this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);
    },
    async updateActiveAgentBot() {
      try {
        await this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: this.inbox.id,
          bot: this.selectedAgentBotId ? {
            outgoing_url: this.selectedAgentBotId,
            name: 'Studio Bot',
            bot_type: 'studio',
          } : undefined,
        });
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.ERROR_MESSAGE'));
      }
    },
    async disconnectBot() {
      try {
        await this.$store.dispatch('agentBots/disconnectBot', {
          inboxId: this.inbox.id,
        });
        this.showAlert(
          this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          error?.message ||
            this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button--disconnect {
  @apply ml-2;
}
</style>
