<template>
  <banner v-if="content" color-scheme="alert" :banner-message="content" />
</template>
<script>
import { mapGetters } from 'vuex';
import Banner from 'dashboard/components/ui/Banner';

export default {
  components: { Banner },
  data() {
    return {
      descriptionKey: 'red_notice',
    };
  },
  computed: {
    ...mapGetters({
      getDescription: 'online99Extra/getDescription',
      uiFlags: 'online99Extra/getUIFlags',
    }),
    content() {
      return this.getDescription(this.descriptionKey);
    },
  },
  mounted() {
    this.$store.dispatch('online99Extra/show', this.descriptionKey);
  },
  methods: {},
};
</script>
