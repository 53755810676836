/* eslint-disable no-cond-assign */
/* eslint-disable no-plusplus */
import { icons, MenuItem } from 'prosemirror-menu';
import { toggleMark } from 'prosemirror-commands';
import {openPrompt} from "@chatwoot/prosemirror-schema/src/prompt";
import {TextField} from "@chatwoot/prosemirror-schema/src/TextField";


// Helpers to create specific types of items

function cmdItem(cmd, options) {
  let passedOptions = {
    label: options.title,
    run: cmd,
  };
  Object.keys(options).reduce((acc, optionKey) => {
    acc[optionKey] = options[optionKey];
    return acc;
  }, passedOptions);
  if ((!options.enable || options.enable === true) && !options.select)
    passedOptions[options.enable ? 'enable' : 'select'] = state => cmd(state);

  return new MenuItem(passedOptions);
}

function markActive(state, type) {
  let { from, $from, to, empty } = state.selection;
  if (empty) return type.isInSet(state.storedMarks || $from.marks());
  return state.doc.rangeHasMark(from, to, type);
}

function markItem(markType, options) {
  let passedOptions = {
    active(state) {
      return markActive(state, markType);
    },
    enable: true,
  };
  Object.keys(options).reduce((acc, optionKey) => {
    acc[optionKey] = options[optionKey];
    return acc;
  }, passedOptions);
  return cmdItem(toggleMark(markType), passedOptions);
}

function linkItem(markType) {
  return new MenuItem({
    title: 'Add or remove link',
    icon: icons.link,
    active(state) {
      return markActive(state, markType);
    },
    enable(state) {
      return !state.selection.empty;
    },
    run(state, dispatch, view) {
      if (markActive(state, markType)) {
        toggleMark(markType)(state, dispatch);
        return true;
      }
      openPrompt({
        title: 'Create a link',
        fields: {
          href: new TextField({
            label: 'https://example.com',
            class: 'small',
            required: true,
          }),
        },
        callback(attrs) {
          toggleMark(markType, attrs)(view.state, view.dispatch);
          view.focus();
        },
      });
      return false;
    },
  });
}


export function buildMenuItems(schema, enableVariables = false) {
  let r = {};
  let type;
  if ((type = schema.marks.strong))
    r.toggleStrong = markItem(type, {title: 'Toggle strong', icon: {text: 'B', css:"font-weight: bold;"}});
  if ((type = schema.marks.em))
    r.toggleEm = markItem(type, { title: 'Toggle emphasis', icon: {text: 'I', css: "font-style: italic;font-size:1em;"}});
  if ((type = schema.marks.s))
    r.toggleS = markItem(type, { title: 'Toggle strikethrough', icon: {text: '\uA7A8'} });

  r.toggleEmoji = new MenuItem({icon: {text: '😀'},
    run: ((state, dispatch, view) => {
      view.onEmoji()

    })
  });

  if (enableVariables) {
    Array.from({ length: 10 }, (_, i) => i + 1).forEach(number => {
      r[`toggleParam${number}`] = new MenuItem({
        icon: { text: `{{${number}}}` },
        run: (state, dispatch, view) => {
          dispatch(state.tr.insertText(`{{${number}}}`));
          view.focus();
        },
      });
    });
  }

  let cut = arr => arr.filter(x => x);

  r.inlineMenu = [
    cut([
      r.toggleStrong,
      r.toggleEm,
      r.toggleS,
      r.toggleEmoji,
      r.toggleParam1,
      r.toggleParam2,
      r.toggleParam3,
      r.toggleParam4,
      r.toggleParam5,
      r.toggleParam6,
      r.toggleParam7,
      r.toggleParam8,
      r.toggleParam9,
      r.toggleParam10,
    ]),
  ];

  return r;
}
