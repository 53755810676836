import ApiClient from './ApiClient';

class AgentBotsAPI extends ApiClient {
  constructor() {
    super('agent_bots', { accountScoped: true });
  }
  
    async getStudioBots(params) {
        return axios.get(`${this.baseUrl()}/integrations/dstudio/bots`, { params });
    }
}

export default new AgentBotsAPI();
