<template>
  <div>
    <div class="attachment-wrapper">
      <div v-if="headerSampleFile" class="attachment-preview-box">
        <attachments-preview
          :attachments="[headerSampleFile]"
          :remove-attachment="removeAttachment"
        />
      </div>
      <file-upload
        v-else
        ref="upload-template-header"
        name="templates-header-file-upload"
        :size="4096 * 4096"
        :accept="allowedAttachments"
        :drop="false"
        :drop-directory="false"
        @input-file="onFileUpload"
      >
        <woot-button
          v-if="allowedAttachments"
          class-names="button--upload"
          color-scheme="secondary"
          variant="smooth"
        >
          {{ $t('CONVERSATION_ACTIONS.BUTTONS_VIEW.FIELDS.FILE.LABEL') }}
        </woot-button>
      </file-upload>
    </div>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component';
import AttachmentsPreview from 'dashboard/components/widgets/AttachmentsPreview';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper.js';
import { onlineWaEditTemplateMixin } from '../../../routes/dashboard/settings/onlinewasettings/templates/forms/helpers/validations';
import alertMixin from '../../../../shared/mixins/alertMixin';

export default {
  components: {
    FileUpload,
    AttachmentsPreview,
  },
  mixins: [onlineWaEditTemplateMixin, alertMixin],
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headerSampleFile: null,
    };
  },
  computed: {
    allowedAttachments() {
      return 'image/png, image/jpeg, video/mp4, application/pdf';
    },
  },
  methods: {
    onFileUpload(file) {
      if (!file) {
        return;
      }
      const headerType =
        {
          'image/png': 2,
          'image/jpeg': 2,
          'video/mp4': 3,
          'application/pdf': 4,
        }[file.file.type] || 1;
      this.$emit('input', headerType);
      const MAXIMUM_FILE_UPLOAD_SIZE =
        { 1: 0, 2: 5, 3: 16, 4: 10 }[headerType] || 0;

      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onloadend = () => {
          const attacment = {
            resource: file,
            thumb: reader.result,
          };
          this.headerSampleFile = attacment;
          this.$emit('file', file.file);
        };
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    removeAttachment() {
      this.$set(this, 'headerSampleFile', undefined);
      this.$emit('file', this.headerSampleFile);
    },
  },
};
</script>
