<template>
  <div class="flex justify-between">
    <div v-for="option in options" :key="option.value">
      <woot-button
        variant="smooth"
        color-scheme="secondary"
        :icon="option.icon"
        :class-names="option.value === value ? 'font-bold' : ''"
        @click="$emit('input', option.value)"
      >
        {{ option.label }}
      </woot-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          label: this.$t('CONVERSATION_ACTIONS.MODAL.TYPE_OPTIONS.BUTTONS'),
          value: 'buttons',
          icon: 'link',
        },
        {
          label: this.$t('CONVERSATION_ACTIONS.MODAL.TYPE_OPTIONS.LIST'),
          value: 'List',
          icon: 'list',
        },
        {
          label: this.$t('CONVERSATION_ACTIONS.MODAL.TYPE_OPTIONS.CONTACT'),
          value: 'contact',
          icon: 'contact-card-group',
        },
      ],
    };
  },
};
</script>
