<template>
  <div class="relative">
    <woot-button
      v-tooltip.top-end="$t('CONVERSATION_ACTIONS.BUTTON_POPOVER')"
      icon="grid"
      color-scheme="secondary"
      variant="smooth"
      size="small"
      @click="openModal = true"
    />

    <woot-modal :show.sync="openModal" :on-close="closeModal">
      <div class="column">
        <div style="height: 60vh">
          <woot-modal-header
            :header-title="$t('CONVERSATION_ACTIONS.MODAL.HEADER')"
          />
          <div class="modal-content">
            <div class="flex mb-4">
              <div class="w-3/5">
                <wa-type-selector
                  :value="currentView"
                  @input="currentView = $event"
                />
              </div>
            </div>
            <component
              :is="viewComponent"
              :value="item"
              @valid="valid = $event"
              @input="item = $event"
            />
          </div>
          <div class="modal-footer">
            <div class="pb-8 px-8 pt-0">
              <woot-button :is-disabled="!valid" @click="onSend">
                {{ $t('CONVERSATION_ACTIONS.MODAL.ACTION_BUTTON') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
const capitalizeFirstLetter = str => {
  return str ? str[0].toUpperCase() + str.slice(1) : '';
};

import ButtonsView from './WaOptionFields/ButtonsView.vue';
import ContactView from './WaOptionFields/ContactView.vue';
import ListView from './WaOptionFields/ListView.vue';
import WaTypeSelector from './WaTypeSelector.vue';
export default {
  components: {
    WaTypeSelector,
    ButtonsView,
    ListView,
    ContactView,
  },
  props: {
    conversationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    openModal: false,
    currentView: 'buttons',
    item: {},
    valid: false,
  }),
  computed: {
    viewComponent() {
      return `${capitalizeFirstLetter(this.currentView)}View`;
    },
    ...mapGetters({
      messageSignature: 'getMessageSignature',
    }),
  },
  mounted() {},
  methods: {
    onSend() {
      const params = {
        conversationId: this.conversationId,
        isPrivate: false,
        private: false,
        ...this[`parse${capitalizeFirstLetter(this.currentView)}`](),
      };
      if (this.messageSignature) {
        params.contentAttributes = {
          ...params.contentAttributes,
          message_signature: this.messageSignature,
        };
      }
      this.$store.dispatch('createPendingMessageAndSend', params);
      this.closeModal();
    },
    closeModal() {
      this.openModal = false;
    },
    parseList() {
      const { items, body, header, footer, buttonText } = this.item;
      return {
        contentType: 'list',
        message: body,
        contentAttributes: {
          buttons_info: items,
          buttons_attributes: {
            buttonText,
            footer,
            header,
          },
        },
      };
    },
    parseButtons() {
      const { buttons, body, footer, header, file } = this.item;
      const obj = {
        contentType: 'buttons',
        message: body,
        contentAttributes: {
          buttons_info: buttons,
          buttons_attributes: {
            footer,
            header,
          },
        },
      };
      if (file) {
        obj.files = [file];
      }
      return obj;
    },
    parseContact() {
      return {
        message: '',
        contentType: 'contact_info',
        contentAttributes: {
          contact_info: this.item,
        },
      };
    },
  },
};
</script>
