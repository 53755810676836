<template>
   <div
      v-on-clickaway="onCloseDropdown"
      class="relative"
      @keyup.esc="onCloseDropdown"
    >
      <div :class="{ 'dropdown-pane--open': isOpen }" class="dropdown-pane">
        <div class="dropdown-wrap">
          <div class="flex justify-start items-start flex-auto">
            <div class="w-full">
              <woot-dropdown-menu>
                <woot-dropdown-item
                  v-for="option in options"
                  :key="option.id"
                >
                  <button
                    :disabled="option.disabled"
                    class="leading-4 my-0 overflow-hidden whitespace-nowrap text-ellipsis text-base p-3 rounded-md w-full hover:bg-slate-25"
                    :class="{'bg-slate-70 opacity-50 cursor-not-allowed hover:bg-gray-300': option.disabled && !option.label, 'cursort-pointer': !option.label }"
                    :title="option.name"
                    @click="() => {
											onCloseDropdown();
											option.action();
										}"
                  >
                    <b v-if="option.label">
                      {{ option.name }}
                    </b>
                    <span v-else>
                      {{ option.name }}
                    </span>
                    </br>
                    <span v-if="option.description" class="text-sm">{{ option.description }}</span>
                  </button>
                </woot-dropdown-item>
              </woot-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
      <woot-button
        class-names="button--upload"
        color-scheme="secondary"
        variant="smooth"
        @click="toggleSelect"
      >
        <i class="icon ion-android-add-circle" />
        {{ $t('WA_SETTINGS.TEMPLATES.ADD.QUICKLY_BTN_TXT') }}
      </woot-button>
    </div>	
</template>
<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
	},
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {},
  methods: {
    onCloseDropdown() {
      this.isOpen = false;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>