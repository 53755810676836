'use strict';

export default function ins_plugin(md) {
  paragraphRule(md)
  newLineRule(md)
  createRule(md, 'wastrikethrough', 's', '~')
  createRule(md, 'waem', 'em', '_')
  createRule(md, 'wastrong', 'strong', '*')
}

function createRule(md, ruleName, tag, markup) {
  // Insert each marker as a separate text token, and add it to delimiter list
  //
  function tokenize(state, silent) {
    var i, scanned, token, len, ch,
      start = state.pos,
      marker = state.src.charAt(start);

    if (silent) { return false; }

    if (marker !== markup) { return false; }

    scanned = state.scanDelims(state.pos, true);
    len = scanned.length;
    ch = marker;

    for (i = 0; i < len; i += 1) {
      token = state.push('text', '', 0);
      token.content = ch;

      state.delimiters.push({
        marker: marker,
        length: 0,     // disable "rule of 3" length checks meant for emphasis
        token:  state.tokens.length - 1,
        end:    -1,
        open:   scanned.can_open,
        close:  scanned.can_close
      });
    }

    state.pos += scanned.length;

    return true;
  }

  // Walk through delimiter list and replace text tokens with tags
  //
  function postProcess(state, delimiters) {
    var i, j,
      startDelim,
      endDelim,
      token,
      loneMarkers = [],
      max = delimiters.length;

    for (i = 0; i < max; i++) {
      startDelim = delimiters[i];

      if (startDelim.marker !== markup) {
        continue;
      }

      if (startDelim.end === -1) {
        continue;
      }

      endDelim = delimiters[startDelim.end];

      token         = state.tokens[startDelim.token];
      token.type    = `${tag}_open`;
      token.tag     = tag;
      token.nesting = 1;
      token.markup  = markup;
      token.content = '';

      token         = state.tokens[endDelim.token];
      token.type    = `${tag}_close`;
      token.tag     = tag;
      token.nesting = -1;
      token.markup  = markup;
      token.content = '';

      if (state.tokens[endDelim.token - 1].type === 'text' &&
        state.tokens[endDelim.token - 1].content === markup) {

        loneMarkers.push(endDelim.token - 1);
      }
    }

    while (loneMarkers.length) {
      i = loneMarkers.pop();
      j = i + 1;

      while (j < state.tokens.length && state.tokens[j].type === `${tag}_close`) {
        j++;
      }

      j--;

      if (i !== j) {
        token = state.tokens[j];
        state.tokens[j] = state.tokens[i];
        state.tokens[i] = token;
      }
    }
  }

  md.inline.ruler.before('emphasis', ruleName, tokenize);
  md.inline.ruler2.before('emphasis', ruleName, function (state) {
      var curr,
        tokens_meta = state.tokens_meta,
        max = state.tokens_meta.length;

      postProcess(state, state.delimiters);

      for (curr = 0; curr < max; curr++) {
        if (tokens_meta[curr] && tokens_meta[curr].delimiters) {
          postProcess(state, tokens_meta[curr].delimiters);
        }
      }
    }
  );
}

function newLineRule(md) {
  // Insert each marker as a separate text token, and add it to delimiter list
  //
  function tokenize(state) {
    var pos = state.pos;
    if (state.src.charCodeAt(pos) !== 0x0A/* \n */) { return false; }
    state.pos += 1;
    state.push('hardbreak', 'br', 0);

    return true;
  }

  md.inline.ruler.before('emphasis', 'wanewline', tokenize);

}

function paragraphRule(md) {
  function tokenize(state, startLine, endLine) {
    var content, token,
      oldParentType = state.parentType,
      nextLine = endLine

    state.parentType = 'paragraph';

    content = state.getLines(startLine, nextLine, state.blkIndent, false).trim();

    state.line = nextLine;

    token          = state.push('paragraph_open', 'p', 1);
    token.map      = [ startLine, state.line ];

    token          = state.push('inline', '', 0);
    token.content  = content;
    token.map      = [ startLine, state.line ];
    token.children = [];

    // noinspection JSUnusedAssignment
    token          = state.push('paragraph_close', 'p', -1);

    state.parentType = oldParentType;

    return true;
  }

  md.block.ruler.before('paragraph', 'paragraphSimple', tokenize);

}

