/* eslint no-console: 0 */
/* global axios */
import ApiClient, {Utility} from '../ApiClient';

export const buildCreatePayload = ({
  message,
  isPrivate,
  contentType,
  contentAttributes,
  echoId,
  files,
  ccEmails = '',
  bccEmails = '',
  toEmails = '',
  forwarded, // d99d
  templateParams,
  delay_hours = null,
}) => {
  let payload;
  if (files && files.length !== 0) {
    payload = new FormData();
    if (message) {
      payload.append('content', message);
    }
    if (contentType) {
      payload.append('content_type', contentType);
    }
    files.forEach(file => {
      payload.append('attachments[]', file);
    });
    payload.append('private', isPrivate);
    payload.append('echo_id', echoId);
    payload.append('cc_emails', ccEmails);
    payload.append('bcc_emails', bccEmails);
    if (forwarded) payload.append('forwarded', forwarded); // d99d
    contentAttributes && Utility.convertModelToFormData(contentAttributes, payload, 'content_attributes') // d99d
    if (toEmails) {
      payload.append('to_emails', toEmails);
    }
    if (delay_hours) {
      payload.append('delay_hours', delay_hours);
    }
  } else {
    payload = {
      content: message,
      private: isPrivate,
      echo_id: echoId,
      content_attributes: contentAttributes,
      content_type: contentType,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      to_emails: toEmails,
      forwarded: forwarded, // d99d
      template_params: templateParams,
      delay_hours: delay_hours,
    };
  }
  return payload;
};

class MessageApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create({
    conversationId,
    message,
    private: isPrivate,
    contentAttributes,
    contentType,
    echo_id: echoId,
    files,
    file, // d99d
    ccEmails = '',
    bccEmails = '',
    toEmails = '',
    forwarded, // d99d
    templateParams,
    delay_hours,
  }) {
    return axios({
      method: 'post',
      url: `${this.url}/${conversationId}/messages`,
      data: buildCreatePayload({
        message,
        isPrivate,
        contentAttributes,
        contentType,
        echoId,
        files: file ? [file] : files, // d99d support file, because creating conversation use only single file
        ccEmails,
        bccEmails,
        toEmails,
        forwarded, // d99d
        templateParams,
        delay_hours,
      }),
    });
  }

  delete(conversationID, messageId) {
    return axios.delete(`${this.url}/${conversationID}/messages/${messageId}`);
  }

  getPreviousMessages({ conversationId, after, before }) {
    const params = { before };
    if (after && Number(after) !== Number(before)) {
      params.after = after;
    }
    return axios.get(`${this.url}/${conversationId}/messages`, { params });
  }

  getFileMessages(id) {
    return axios.get(`${this.url}/${id}/messages/files`);
  }

  translateMessage(conversationId, messageId, targetLanguage) {
    return axios.post(
      `${this.url}/${conversationId}/messages/${messageId}/translate`,
      {
        target_language: targetLanguage,
      }
    );
  }
}

export default new MessageApi();
