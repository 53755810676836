<template>
  <div class="row">
    <div class="medium-12 column">
      <woot-input
        v-model.trim="$v.header.$model"
        :class="{ error: $v.header.$error }"
        class="medium-12 columns"
        :label="$t('CONVERSATION_ACTIONS.LIST_VIEW.FIELDS.HEADER.LABEL')"
        :error="getErrorMessage($v.header)"
      />
      <wa-message-body-input
        :value="$v.body"
        @input="updateField('body', $event)"
      />
      <wa-message-footer-input
        :value="$v.footer"
        @input="updateField('footer', $event)"
      />
      <woot-input
        v-model.trim="$v.buttonText.$model"
        :class="{ error: $v.buttonText.$error }"
        class="medium-12 columns"
        :label="$t('CONVERSATION_ACTIONS.LIST_VIEW.FIELDS.TITLE.LABEL')"
        :placeholder="$t('CONVERSATION_ACTIONS.LIST_VIEW.FIELDS.TITLE.LABEL')"
        :error="getErrorMessage($v.buttonText)"
      />
    </div>
    <woot-button
      v-tooltip.right="$t('CONVERSATION_ACTIONS.LIST_VIEW.ADD_ITEM')"
      variant="smooth"
      color-scheme="secondary"
      class="btn-filter"
      :is-disabled="disableAddButton"
      @click="addItem"
    >
      <i class="icon ion-android-add-circle" />
      {{ $t('CONVERSATION_ACTIONS.LIST_VIEW.ADD_ITEM') }}
    </woot-button>

    <div class="medium-12 column">
      <list-item-option
        v-for="(item, idx) in $v.items.$each.$iter"
        :key="idx"
        :order="parseInt(idx) + 1"
        :value="item"
        @input="updateItem(idx, $event)"
        @remove="removeItem(idx)"
      />
    </div>
  </div>
</template>
<script>
import { maxLength } from 'vuelidate/lib/validators';
import ListItemOption from './ListItemOption.vue';
import WaMessageBodyInput from './WaMessageBodyInput.vue';
import WaMessageFooterInput from './WaMessageFooterInput.vue';
import { required } from 'vuelidate/lib/validators';
import { minLength } from 'vuelidate/lib/validators';
import { onlineWaEditTemplateMixin } from '../../../routes/dashboard/settings/onlinewasettings/templates/forms/helpers/validations';

export default {
  components: {
    WaMessageBodyInput,
    WaMessageFooterInput,
    ListItemOption,
  },
  mixins: [onlineWaEditTemplateMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      body: '',
      header: null,
      footer: null,
      buttonText: '',
    };
  },
  computed: {
    addButtonOptions() {
      return [
        {
          name: 'new list',
          id: 'website',
          description: '',
          disabled: this.disableAddButton,
          action: () => {
            this.addButton({});
          },
        },
      ];
    },
    disableAddButton() {
      console.log(this.items.length, this.$v.items.$params.maxLength.max, this.items.length >= this.$v.items.$params.maxLength.max)
      return this.items.length >= this.$v.items.$params.maxLength.max;
    },
  },
  watch: {
    items() {
      this.sync();
    },
    buttonText() {
      this.sync();
    },
    body() {
      this.sync();
    },
    header() {
      this.sync();
    },
    footer() {
      this.sync();
    },
  },
  created() {
    this.addItem({});
    this.$v.$touch();
  },
  validations: {
    header: {
      maxLength: maxLength(60),
    },
    footer: {
      maxLength: maxLength(60),
    },
    body: {
      required,
      maxLength: maxLength(1024),
    },
    buttonText: {
      required,
      maxLength: maxLength(20),
    },
    items: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(10),
      $each: {
        itemTitle: {
          required,
          maxLength: maxLength(24),
        },
        itemDescription: {
          maxLength: maxLength(72),
        },
      },
    },
  },
  methods: {
    updateField(key, value) {
      this.$set(this, key, value);
      this.$v[key].$touch();
    },

    addItem() {
      this.items.push({
        itemTitle: '',
        itemDescription: '',
      });
      this.$v.items.$touch();
    },
    updateItem(idx, button) {
      const newButton = { ...this.items[idx], ...button };
      this.$set(this.items, idx, newButton);
      this.$v.items.$touch();
    },
    removeItem(idx) {
      this.$delete(this.items, idx);
      this.$v.items.$touch();
    },
    sync() {
      const { items, body, header, footer, buttonText } = this;
      this.$emit('input', { items, body, header, footer, buttonText });
      this.$emit('valid', !this.$v.$invalid);
    },
  },
};
</script>
