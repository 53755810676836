export const FEATURE_FLAGS = {
  AGENT_BOTS: 'agent_bots',
  AGENT_MANAGEMENT: 'agent_management',
  AUTO_RESOLVE_CONVERSATIONS: 'auto_resolve_conversations',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  CANNED_RESPONSES: 'canned_responses',
  CRM: 'crm',
  CUSTOM_ATTRIBUTES: 'custom_attributes',
  INBOX_MANAGEMENT: 'inbox_management',
  INTEGRATIONS: 'integrations',
  LABELS: 'labels',
  MACROS: 'macros',
  HELP_CENTER: 'help_center',
  REPORTS: 'reports',
  TEAM_MANAGEMENT: 'team_management',
  VOICE_RECORDER: 'voice_recorder',
  AUDIT_LOGS: 'audit_logs',
  MESSAGE_REPLY_TO: 'message_reply_to',
  SLA: 'sla',
  STUDIO: 'studio',
};
