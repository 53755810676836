import { render, staticRenderFns } from "./EmojiInput.vue?vue&type=template&id=32994489&scoped=true&"
import script from "./EmojiInput.vue?vue&type=script&lang=js&"
export * from "./EmojiInput.vue?vue&type=script&lang=js&"
import style0 from "./EmojiInput.vue?vue&type=style&index=0&id=32994489&prod&scoped=true&lang=css&"
import style1 from "./EmojiInput.vue?vue&type=style&index=1&id=32994489&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32994489",
  null
  
)

export default component.exports